import Me from '../vues/Me.vue'
import Parcours from '../vues/Parcours.vue'
import Competences from "@/vues/Competences";
import Contact from "@/vues/Contact";
import Hobbies from "../vues/Hobbies";
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Me
  },
  {
    path: '/journey',
    name: 'Journey',
    component: Parcours,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Competences,
  },
  {
    path: '/hobbies',
    name: 'Hobbies',
    component: Hobbies,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;