<template>
    <div id="navbar">
      <div id="navbar-menu"><Menu  /></div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
</template>

<script>
import Menu from '@/components/Menu';
export default {
  components: {Menu}
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: white;
  background-image: linear-gradient(108.9deg, #3643ba 11.2%, #eea466 88.9%);


  background-repeat: repeat;
  background-attachment:scroll;
  min-height: 94vh;
  padding: 3vh 3vw;
}
#navbar {
  display: flex;
}
#navbar-menu{
  margin: auto;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave {}

.fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 0;
}


</style>
