<template>
  <div class="me">
    <div class="me-myname">
      Baptiste Boyer
    </div>
    <div class="me-myjob">
      Frontend Software Development Engineer
    </div>
    <img class="me-photo" alt="Baptiste Boyer" src="/optimized-images/me.webp">
    <div class="me-description">
      Hello! My name is Baptiste, I'm 25 years old and I'm from Lille, France. <br><br>
      Passionate about computers since I was young, I logically chose to do IT studies after the baccalaureate and here I am, holder of an engineering degree specializing in computer science and statistics!
    </div>
  </div>
</template>

<script>
export default {
  name: 'Me',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.me{
  text-align: center;
}
.me-myname{
  font-size: min(9.5vw, 90px);
  margin: 5vh auto auto auto;
  font-weight: bold;
}
.me-myjob{
  font-weight: bold;
  font-size: min(3.5625vw, 33.75px);
}
.me-photo{
  width: min(175px, 30vw);
  border-radius: 50%;
  border: 4px solid white;
  margin-top: 4vh;
}
.me-description{
  width: 70%;
  margin: 5vh auto auto auto;
}
@media (min-width: 320px) and (max-width: 480px) {

  .me-myname{
    margin: 5vh auto auto auto;
  }

  .me-description{
    width: 100%;
  }
}
</style>
